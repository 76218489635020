import React from 'react';
import {Link, graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import PostMeta from '../components/post-meta';
import Tags from '../components/tags';
import {useSiteMetadata} from '../utils/use-site-metadata';

export default function Home({data}) {
  const {edges: posts} = data.allMarkdownRemark;
  const {title, description, siteUrl} = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>
          {title} · {description}
        </title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={siteUrl} />
      </Helmet>

      <ul className="post-listing">
        {posts
          .filter((post) => post.node.frontmatter.title.length > 0)
          .map(({node: post}) => {
            return (
              <li className="post-teaser" key={post.id}>
                <Tags tags={post.frontmatter.tags} />

                <Link className="post-teaser__title" to={post.frontmatter.path}>
                  {post.frontmatter.title}
                </Link>

                <PostMeta post={post} />
              </li>
            );
          })}
      </ul>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          frontmatter {
            author
            date
            shortDate: date(formatString: "MMMM Do, YYYY")
            path
            tags
            title
          }
        }
      }
    }
  }
`;
